import type { ComponentPropsWithoutRef } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar';
import { displayName } from '~/lib/user/displayName';
import { cn } from '~/lib/utils';

type Props = ComponentPropsWithoutRef<typeof Avatar> & {
  user: {
    image?: string | null;
    email?: string | null;
    name?: string | null;
  };
  size?: 'large' | 'medium' | 'small';
};

export const UserAvatar = ({
  user: { image, email, name },
  size = 'medium',
  className,
  ...props
}: Props) => {
  const finalName = displayName({
    email,
    name: name ?? '?',
  });

  const finalImage = image;

  return (
    <Avatar
      className={cn(
        {
          'h-8 w-8': size === 'small',
          'h-12 w-12': size === 'medium',
          'h-32 w-32': size === 'large',
        },
        className
      )}
      {...props}
    >
      <AvatarFallback
        className={cn({
          'text-base': size === 'small',
          'text-lg': size === 'medium',
          'text-3xl': size === 'large',
        })}
      >
        {name?.[0]?.toUpperCase() ?? email?.[0]?.toUpperCase() ?? 'X'}
      </AvatarFallback>
      {finalImage ? (
        <AvatarImage className="object-cover" src={finalImage} alt={finalName} />
      ) : null}
    </Avatar>
  );
};
