'use client';

import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { Button, buttonVariants } from '~/components/ui/button';
import { getServerUrl } from '~/lib/url/serverUrl';
import { UserAvatar } from './UserAvatar';
import { UserDropdown } from './UserDropdown';

export const ClientCurrentUser = () => {
  const session = useSession();

  if (session.data?.user) {
    return (
      <UserDropdown>
        <Button variant="ghost" size="sm">
          <UserAvatar
            size="small"
            className="size-8 rounded-full"
            user={session.data.user}
          />
        </Button>
      </UserDropdown>
    );
  }

  const currentUrl = getServerUrl();

  return (
    <Link
      className={buttonVariants({ size: 'sm', variant: 'ghost' })}
      href={`${currentUrl}/login?callbackUrl=${currentUrl}`}
    >
      Sign in
    </Link>
  );
};
