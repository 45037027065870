export const getServerUrl = (): string => {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  }

  if (process.env.IS_PRODUCTION) {
    return 'https://codeline.app/';
  }

  if (process.env.DEV_URL) {
    return process.env.DEV_URL;
  }

  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}/`;
  }

  return 'http://localhost:3000/';
};
