import type { PropsWithChildren } from 'react';
import { ClientCurrentUser } from '~/features/auth/ClientCurrentUser';
import { Notifications } from '~/features/notifications/Notifications';

export const BaseHeaderClient = ({ children }: PropsWithChildren) => {
  return (
    <header className=" items-center border-b border-b-gray-30 bg-card dark:bg-background">
      <div className="m-auto flex max-w-5xl  px-4 py-2">
        {children}
        <div className="ml-auto flex items-center gap-2">
          <Notifications />
          <ClientCurrentUser />
        </div>
      </div>
    </header>
  );
};
