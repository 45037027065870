export const displayName = ({
  email,
  name,
}: {
  email?: string | null;
  name?: string | null;
}) => {
  if (name) {
    return name;
  }
  if (email) {
    return email;
  }
  return 'Anonymous';
};

export const discordDisplayName = ({
  email,
  name,
  discordId,
}: {
  email?: string | null;
  name?: string | null;
  discordId?: string | null;
}) => {
  if (discordId) {
    return `<@${discordId}>`;
  }

  if (name) {
    return `${name} (${email})`;
  }

  return `${email}`;
};
